import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import $ from "jquery";
import {
  approveAction,
  getAllowanceDetails,
  JoinNowAction,getTransactionInfo
} from "../Action/ContractActions.js";

import {
  plandata,
  getuserplandata,
  getadmindetails,
} from "../Action/apiAction";

import Web3Modal from "web3modal";
import { connection } from "../helper/connection";
import { setWallet } from "../reducers/Actions";
import { toFixedFormat } from "../helper/customMath";
import config from "../config/config";
import { providerOptions } from "../config/providerOptions";
import { WalletClient, useWalletClient, useDisconnect } from "wagmi";
import { useConnect } from "wagmi";
import { toastAlert } from "../helper/toastAlert";
import { BrowserProvider, JsonRpcSigner } from "ethers";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
import DefiQABI from "../ABI/DefiQABI";
import Busdabi from "../ABI/BusdABI.json";
import logos from "../assets/images/logo.png";
import ReactLoading from "react-loading";

import isEmpty from "is-empty";
import { useParams } from "react-router-dom";

import { convert } from "../helper/convert.js";

import BusdABI from "../ABI/BusdABI.json";

import { Scrollbars } from "react-custom-scrollbars-2";

export default function Navbar(props) {
  const timerRef = useRef(null);
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { disconnect } = useDisconnect();
  let chainid = config.NetworkId;
  console.log(chainid, 'chainid');
  const { data: walletClient } = useWalletClient({ chainid });
  console.log(walletClient, 'wd');
  const dispatch = useDispatch();
  const [data, setdata] = useState({});
  const [selected, setSelected] = useState("GB");
  const [balance, setbalance] = useState("");
  const [useraddress, setuseraddress] = useState("");
  const [userExist, setUserExist] = useState(false);
  const walletConnection = useSelector((state) => state.walletConnection);

  var { ref } = useParams();
  // alert(id)
  const [referalAddress, setReferalAddress] = useState(
    ref != undefined ? ref : ""
  );
  const [Error, setError] = useState("");
  const [load, setload] = useState(false);
  const [loadapprove, setloadapprove] = useState(false);
  const [approve, setApprove] = useState();
  const [approvedamt, setapprovedamt] = useState(0);
  const [joinedamt, setjoinedamt] = useState(0);

  const Connection = useSelector((state) => state.walletConnection);
  const [walletconnection, setwalletconnection] = useState({});

  const [Plandata, setPlandata] = useState([]);
  const [userplandata, setuserplandata] = useState([]);
  const [useralldata, setuseralldata] = useState([]);

  const [joinplanPid, setjoinplanPid] = useState(0);
  const [joinplanAmount, setjoinplanAmount] = useState();

  const [adminreferrealdata, setadminreferrealdata] = useState([]);

  const [getbnbbal, setgetbnbbal] = useState();

  const [addfrom, setaddfrom] = useState(false);
  const [planName, setplanName] = useState();
  const [getbuttondiable, setbuttondiable] = useState(false);

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  useEffect(() => {
    checkConnect();

    // getuser()
  }, [walletClient]);

  console.log(walletConnection, "walletConnection");

  const handlechange = (e) => {
    const { value } = e.target;
    setError();
    setReferalAddress(value);
  };

  const handleclose = (e) => {
    // alert(e.target.value)
    setReferalAddress("");
    setError("");
    setaddfrom(false);
  };

  async function logout() {
    localStorage.clear();
    disconnect();
    setTimeout(function () {
      window.location.href = "/";
    }, 700);
  }

  async function checkConnect() {
    console.log(walletClient, 'walletClient');
    if (walletClient) {

      const { account, chain, transport } = walletClient;
      const network = {
        chainId: chain.id,
        name: chain.name,
        ensAddress: chain.contracts?.ensRegistry?.address,
      };

      const provider = new BrowserProvider(transport, network);
      const signer = new JsonRpcSigner(provider, account.address);
      var web3 = new Web3(transport);

      let BusdBlanace = new web3.eth.Contract(Busdabi, config.BUSDAddress);

      var result = await web3.eth.getAccounts();
      var networks = await web3.eth.net.getId();

      var getBNBbal = await web3.eth.getBalance(result[0]);
      console.log(getBNBbal / 1e18);
      setgetbnbbal(getBNBbal / 1e18);
      let bnbbalance = await BusdBlanace.methods.balanceOf(result[0]).call();
      console.log(bnbbalance, result[0], "bnbbalance");
      let deficontract = new web3.eth.Contract(DefiQABI, config.ambasscontract);
      let userdetails = await deficontract.methods
        .userInfo(1, result[0])
        .call();
      if (networks == config.NetworkId) {
        var bal = bnbbalance / 1e18;
        // bal = await toFixedFormat(bal);
        setbalance(bal);
        // console.log(bal, result[0]);
        setuseraddress(result[0]);
        dispatch(
          setWallet({
            network: chain.id,
            web3: transport,
            address: result[0],
            provider: transport,
            connect: "yes",
            userdetails: userdetails,
          })
        );
        localStorage.setItem("ambAddress", result[0])
        getuserBalance(transport, result[0]);
        getuser(result[0]);
        // toastAlert('success', "Wallet connected successfully", 'network');
      } else {
        toastAlert("error", "please select BSC chain network", "network");
        dispatch(
          setWallet({
            network: "",
            web3: "",
            address: "",
            provider: "",
            connect: "no",
            userdetails: "",
          })
        );
      }
    }
  }

  async function getuserBalance(t_web3, currAddr) {
    if (t_web3) {
      var web3 = new Web3(t_web3);
      let deficontract = new web3.eth.Contract(DefiQABI, config.ambasscontract);
      let userdetails = await deficontract.methods.userInfo(1, currAddr).call();
      var isExist =
        userdetails && userdetails.isExist ? userdetails.isExist : false;
      setUserExist(isExist);
    }
  }

  window.addEventListener("load", async (event) => {
    // if (localStorage.getItem("account")) {
    event.preventDefault();
    var provider = window.ethereum;
    var web3 = new Web3(provider);


    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function (accounts) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(async () => {
          var result = await web3.eth.getAccounts();
          var setacc = result[0];
          localStorage.setItem("account", result[0]);
          window.location.href = "/";
        }, 1000);
      });

      window.ethereum.on("networkChanged", async function (networkId) {
        var provider = window.ethereum;
        var web3 = new Web3(provider);
        if (networkId == config.NetworkId) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts();
            var setacc = result[0];

            localStorage.setItem("account", result[0]);
            window.location.href = "/";
          }, 1000);
        } else {
          localStorage.clear();
          window.location.href = "/";
        }
      });
    }
  });

  async function connectAccount(connector) {
    try {
      let check = isMobile();

      if (
        check &&
        !window.ethereum &&
        connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }

      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();

        console.log(
          currnetwork,
          config.NetworkId,
          connector.connector.id,
          "hh"
        );
        if (
          currnetwork != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          toastAlert("error", "Please connect smart chain", "error");
          return;
        }
      }

      localStorage.removeItem("referaladdress");
      let rec = connect(connector);
      console.log(rec, 'rec');
      window.$("#connect_wallet_modal1").modal("hide");
      setTimeout(() => {
        // window.location.reload();
      }, 1000)

    } catch (err) {
      console.log(err, "====+++errrr");
    }
  }

  function isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    // console.log('dappaaaaaaaaaaaaaaaaUrl: ', dappUrl);
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    // console.log(metamaskAppDeepLink, "dapppppp...")
    window.open(metamaskAppDeepLink, "_self");
  };

  async function copyToClipboard(data) {
    navigator.clipboard.writeText(data);
    toastAlert("success", "Copied Successfully", "success");
  }

  const getuser = async (add) => {
    const result = await plandata();
    let reqdata = {
      address: add,
    };
    console.log(reqdata);
    const userdata = await getuserplandata(reqdata);
    console.log(userdata.data.data, result.data.data, "result");
    setuseralldata(userdata.data.data);

    const userplan = await samebetweenarray(
      userdata.data.data,
      result.data.data
    );
    const balplan = await differencebetweenarrayvalue(
      userdata.data.data,
      result.data.data
    );
    setPlandata(balplan);
    setuserplandata(userplan);
    console.log(userplandata, "userplandata");
  };

  const samebetweenarray = async (arr1, arr2) => {
    return arr2.filter((item1) => {
      return arr1.some((item2) => {
        if (item2.PlanID === item1.PlanID) {
          return item2;
        }
      });
    });
  };

  const differencebetweenarrayvalue = async (arr1, arr2) => {
    return arr2.filter((item1) => {
      return !arr1.some((item2) => item2.PlanID === item1.PlanID);
    });
  };

  const onclickjoindata = async (amt, pid, name) => {
    setjoinplanAmount(amt);
    setjoinplanPid(pid);
    setplanName(name);
    console.log(amt, pid, "amt, pid");
    getadmindata(pid);
  };

  const getadmindata = async (pid) => {
    try {
      let reqdata = {
        address: config.AdminAddress,
        planid: pid,
      };

      const result = await getadmindetails(reqdata);
      console.log(result, "result");
      setadminreferrealdata(result.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const rejoinplan = async (pid, amount) => {
    try {
      setbuttondiable(true)

      var get = await connection();
      var web3 = new Web3(get.web3);
      var approveamt = await convert(1000 * 1e18);
      var BuyAmount = await convert(amount * 1e18);

      var BusdBlanace = new web3.eth.Contract(BusdABI, config.BUSDAddress);

      const balace = await BusdBlanace.methods
        .balanceOf(Connection.address)
        .call();

      let balac = balace / 1e18;
      if (balac < amount) {
        setbuttondiable(false)

        toastAlert("error", "User Usdt balance is Low", "error");
        return false;
      }

      setload(true);

      await BusdBlanace.methods
        .approve(config.ambasscontract, approveamt.toString())
        .send({ gasLimit: 250000, from: Connection.address })
        .then(async (res) => {
          var mlmcontract = new web3.eth.Contract(
            DefiQABI,
            config.ambasscontract
          );
          await mlmcontract.methods
            .rejoin(pid, BuyAmount.toString())
            .send({ from: Connection.address })
            .then(async(res) => {
              console.log(res,"getTransactionInfo1");

              const result = await getTransactionInfo(res.transactionHash);
              console.log(result, "getTransactionInfo");
              
              if (result) {
                setload(false);
                setbuttondiable(false)
                toastAlert("success", "Rejoin successfully", "success");
                setTimeout(() => {
                  // window.location.reload();
                }, 1000);
              }

            });
        });
    } catch (err) {
      toastAlert("error", "Transaction Rejected", "error");
      setbuttondiable(false)
      setload(false);
      console.log(err);
    }
  };

  const joinPlan = async (type) => {
    try {
      if (type == "with") {
        if (referalAddress == "") {
          setError("Continue without referral Id");
          return false;
        }
      }
      // setError()

      let refferal =
        referalAddress == "" ? `AMBPSILVER${adminreferrealdata[0].userid}` : referalAddress;

      console.log(refferal, "refferal");
      setload(true);
      const { status, message } = await JoinNowAction(
        joinplanPid,
        joinplanAmount,
        useraddress,
        refferal
      );


      toastAlert("error", message, "error");
      setload(false);
      if (message == "Invalid Referral id") {
        setReferalAddress("");
        setError("Invalid Referral id");
        return false;
      }

      console.log(status, message);
    } catch (err) {
      console.log(err, "errerr");

      setload(false);
    }
  };

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  return (
    <>
      {load && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {walletConnection && walletConnection.address != "" && (
                  <div data-bs-dismiss="offcanvas" className="w-100">
                    <button
                      className="primary_btn "
                      data-bs-toggle="modal"
                      data-bs-target="#wallet_address_modal"
                    >
                      {halfAddrShow(walletConnection.address)}
                    </button>
                  </div>
                )}

                <div class="dropdown">
                  {walletConnection &&
                    walletConnection.address != "" &&
                    userplandata &&
                    userplandata.length != 0 && (
                      <button
                        class="primary_btn"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        My Dashboard
                      </button>
                    )}
                  <ul class="dropdown-menu" style={{ background: "#073242" }}>
                    {userplandata &&
                      userplandata?.map((item) => (
                        <li style={{ margin: "7px" }}>
                          <a
                            class={`dropdown-item ${item.PoolColor.toLowerCase()}_button dash_button`}
                            href={`/dashboard-${item.PoolColor}/${item.PlanID}`}
                          >
                            {item.poolName.toUpperCase()}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
                {walletConnection && walletConnection.address != "" && (
                  <div data-bs-dismiss="offcanvas" className="w-100">
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#choose_standard_modal"
                    >
                      Choose Standards
                    </button>
                  </div>
                )}
                {walletConnection && walletConnection.address == "" && (

                  <div data-bs-dismiss="offcanvas" className="w-100">
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal1"
                    >
                      Connect Wallet
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal1"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
        data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {connectors &&
                    connectors.length > 0 &&
                    connectors.map((connector, index) => {
                      console.log(connector, 'connectorconnectorconnector');
                      var logo = "metamask.png";
                      var name = "MetaMask";
                      if (connector.name === "MetaMask") {
                        logo = "metamask.png";
                        name = "MetaMask";
                      } else if (connector.name === "WalletConnect") {
                        logo = "trustwallet.png";
                        name = "Trust wallet";
                      }
                      return (
                        <a
                          target="_blank"
                          className="mb-3 d-block"
                          onClick={() => connectAccount({ connector })}
                        >
                          <button
                            type="button"
                            className="connect_wallet_button"
                          >
                            <img
                              src={require(`../assets/images/${logo}`)}
                              height="28"
                              width="28"
                              className="img-fluid"
                            />{" "}
                            <span>{name}</span>
                          </button>
                        </a>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Join Now Modal */}
      <div
        className="modal fade primary_modal"
        id="joinnow_modal"
        tabIndex={-1}
        aria-labelledby="joinnow_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="joinnow_ModalLabel">
                Join Now
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleclose}
              ></button>
            </div>
            <div className="modal-body">
              {addfrom && (
                <div className="form-group mb-3">
                  <input
                    placeholder="Enter Referral ID"
                    className="form-control primary_inp"
                    onChange={handlechange}
                    value={ref != undefined ? ref : referalAddress}
                  />
                  <small
                    style={{
                      fontSize: "12px",
                      float: "right",
                      display: "block !important",
                      textAlign: "right !important",
                    }}
                  >
                    Example:AMBP{planName.toUpperCase()}12345
                  </small>

                  {!isEmpty(Error) ? (
                    <span style={{ color: "red" }} className="errorText">
                      {Error}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              )}

              <div className="text-center" style={{ display: "flex" }}>
                {!addfrom && (
                  <button
                    class="primary_btn"
                    style={{ marginRight: "20px" }}
                    onClick={() => setaddfrom(true)}
                  >
                    Add referral
                  </button>
                )}

                {addfrom && isEmpty(Error) && (
                  <button class="primary_btn" onClick={() => joinPlan("with")}>
                    submit
                  </button>
                )}

                <br></br>
                {!addfrom && (
                  <button
                    class="primary_btn"
                    onClick={() => joinPlan("without")}
                  >
                    Continue without referral
                  </button>
                )}
                {!isEmpty(Error) && (
                  <button
                    class="primary_btn"
                    style={{ marginRight: "20px" }}
                    onClick={() => setError("")}
                  >
                    Add referral
                  </button>
                )}
                {!isEmpty(Error) && (
                  <button
                    class="primary_btn"
                    style={{ marginRight: "20px" }}
                    onClick={() => joinPlan("without")}
                  >
                    Continue without referral
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wallet Address Modal */}
      <div
        className="modal fade primary_modal"
        id="wallet_address_modal"
        tabIndex={-1}
        aria-labelledby="wallet_address_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="wallet_address_modalLabel">
                My Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="wallet_modal">
                <div>
                  <label>Address:</label>
                  <label>
                    <span>{halfAddrShow(walletConnection.address)}</span>{" "}
                    <i
                      class="fas fa-copy ms-2"
                      onClick={() => copyToClipboard(walletConnection.address)}
                    ></i>
                  </label>
                </div>
                <div>
                  <label>USDT Balance:</label>
                  <label>{balance}</label>
                </div>
                <div>
                  <label>BNB Balance:</label>
                  <label>{getbnbbal}</label>
                </div>
              </div>
              <div className="text-center">
                <button class="primary_btn" onClick={() => logout()}>
                  Disconnect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Choose Standard Modal */}
      <div
        className="modal fade primary_modal standard_modal"
        id="choose_standard_modal"
        tabIndex={-1}
        aria-labelledby="choose_standard_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="standard_wrapper">
                <div className="standard_wrapper_left">
                  <div className="standard_wrapper_title">
                    <h2>My Standards</h2>
                  </div>
                  <div className="standard_wrapper_body">
                    <Scrollbars
                      style={{ width: "100%", height: 350 }}
                      renderThumbVertical={(props) => (
                        <div {...props} className="thumb-vertical" />
                      )}
                      renderTrackHorizontal={(props) => (
                        <div
                          {...props}
                          style={{ display: "none" }}
                          className="track-horizontal"
                        />
                      )}
                    >
                      {userplandata &&
                        userplandata?.map((item) =>
                          // useralldata.findIndex(planUser => planUser.PlanID === item.PlanID && planUser.uplineAmount === 0) != -1 &&
                          useralldata.findIndex(
                            (planUser) =>
                              planUser.PlanID === item.PlanID &&
                              planUser.userStaus === 0
                          ) != -1 ? (
                            <div className="standards_list">
                              <div className="standards_list_row">
                                <div
                                  class={`standard_circle standard_${item.PoolColor.toLowerCase()}`}
                                ></div>
                                <h3>
                                  {item.poolPrize} USDT{" "}
                                  <span>{item.poolName}</span>
                                </h3>
                                <button
                                  className="std_join_btn"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#joinnow_modal"
                                  disabled={getbuttondiable}

                                  onClick={() =>
                                    rejoinplan(item.PlanID, item.poolPrize)
                                  }
                                >
                                  Rejoin
                                </button>
                              </div>
                            </div>
                          ) : (
                            <a
                              href={`/dashboard-${item.PoolColor.toLowerCase()}/${item.PlanID
                                }`}
                            >
                              <div className="standards_list">
                                <div className="standards_list_row">
                                  <div
                                    class={`standard_circle standard_${item.PoolColor.toLowerCase()}`}
                                  ></div>

                                  <h3>
                                    {item.poolPrize} USDT{" "}
                                    <span>{item.poolName} Dashboard</span>
                                  </h3>
                                </div>
                              </div>
                            </a>
                          )
                        )}
                    </Scrollbars>
                  </div>
                </div>
                <div className="standard_wrapper_right">
                  <div className="standard_wrapper_title">
                    <h2>other Standards</h2>
                  </div>
                  <div className="standard_wrapper_body">
                    <Scrollbars
                      style={{ width: "100%", height: 350 }}
                      renderThumbVertical={(props) => (
                        <div {...props} className="thumb-vertical" />
                      )}
                      renderTrackHorizontal={(props) => (
                        <div
                          {...props}
                          style={{ display: "none" }}
                          className="track-horizontal"
                        />
                      )}
                    >
                      {Plandata?.map((item) => (
                        <div className="standards_list">
                          <div className="standards_list_row">
                            <div
                              class={`standard_circle standard_${item.PoolColor.toLowerCase()}`}
                            ></div>
                            <h3>
                              {item.poolPrize} USDT <span>{item.poolName}</span>
                            </h3>
                            <button
                              className="std_join_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#joinnow_modal"
                              onClick={() =>
                                onclickjoindata(
                                  item.poolPrize,
                                  item.PlanID,
                                  item.poolName
                                )
                              }
                            >
                              Join
                            </button>
                          </div>
                        </div>
                      ))}

                      {/* <div className="standards_list_row">
                          <div class="standard_circle standard_gold"></div>
                          <h3>
                            250 BUSD <span>Gold</span>
                          </h3>
                          <button
                            className="std_join_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#joinnow_modal"
                          >
                            Join
                          </button>
                        </div>
                        <div className="standards_list_row">
                          <div class="standard_circle standard_platinum"></div>
                          <h3>
                            400 BUSD <span>Platinum</span>
                          </h3>
                          <button
                            className="std_join_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#joinnow_modal"
                          >
                            Join
                          </button>
                        </div>
                        <div className="standards_list_row">
                          <div class="standard_circle standard_diamond"></div>
                          <h3>
                            550 BUSD <span>Diamond</span>
                          </h3>
                          <button
                            className="std_join_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#joinnow_modal"
                          >
                            Join
                          </button>
                        </div> */}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
