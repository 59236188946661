import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Link, NavLink, useParams } from "react-router-dom";
import PlanInfo from "../components/Planinfo.js";
import { getuserplandataSingle, getplandataSingle, gethandbookfile } from "../Action/apiAction";
import { getTransactionInfo } from "../Action/ContractActions.js"
import { useSelector } from "react-redux";
import { connection } from "../helper/connection";
import Web3 from "web3";
import config from "../config/config";
import DefiQABI from "../ABI/DefiQABI.json";
import { toastAlert } from "../helper/toastAlert";
import ReactLoading from "react-loading";
import { convert } from "../helper/convert"
import BusdABI from '../ABI/BusdABI.json'
import userpdf from "../assets/pdf/user.pdf";
import Share from "../components/Share.js";
import axios from 'axios'
import fileDownload from 'js-file-download'
import $ from "jquery";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}




export default function DashboardBronze(props) {
  var { id } = useParams();
  const Connection = useSelector((state) => state.walletConnection);

  const [Plandata, setPlandata] = useState("");
  const [Planuserdata, setPlanuserdata] = useState("");
  const [load, setload] = useState(false);
  const [pageload, setpageload] = useState(true);
  const [earnamount, setearnamount] = useState(0);
  const [acbalance, setacbalance] = useState(0);
  const [planIncome, setplanIncome] = useState(0);
  const [gethandbook, setgethandbook] = useState();

  const [getbuttondiable, setbuttondiable] = useState(false);

  const [exitpopup, setexitpopup] = useState(false);




  setTimeout(() => {
    isNaN(earnamount) ?
      setpageload(true) : setpageload(false)
  }, 1000);




  useEffect(() => {
    getPlandata();
    gethandbookdata()
  }, [Connection, pageload]);

  async function gethandbookdata() {
    try {
      var payLoad = { id: id };

      const result = await gethandbookfile(payLoad)
      console.log(result, "result");
      setgethandbook(result?.data?.data[0])
    } catch (err) {
      console.log(err);
    }

  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }


  async function getPlandata() {
    var payLoad = { id: id };
    var userData = await getuserplandataSingle(payLoad);
    setPlandata(userData.data.data);
    var payLoad1 = { id: id, address: Connection.address };
    var usersingleData = await getplandataSingle(payLoad1);
    setPlanuserdata(usersingleData.data.data);

    const earnedamount =
      usersingleData.data.data?.referralReward1 +
      usersingleData.data.data?.referralReward2 +
      usersingleData.data.data?.ambpBonus1 +
      usersingleData.data.data?.ambpBonus2;

    const acbalan =
      usersingleData.data.data?.referralReward1 +
        usersingleData.data.data?.referralReward2 +
        usersingleData?.data?.data?.ambpBonus1 +
        usersingleData.data.data?.ambpBonus2 +
        (usersingleData.data.data?.joinAmount - (((Plandata.poolPrize *
          Plandata.networkFee) / 100) + ((Plandata.poolPrize *
            Plandata.registerFee) / 100))) > 0 ? usersingleData.data.data?.referralReward1 +
            usersingleData.data.data?.referralReward2 +
            usersingleData?.data?.data?.ambpBonus1 +
            usersingleData.data.data?.ambpBonus2 +
      (usersingleData.data.data?.joinAmount - (((Plandata.poolPrize *
        Plandata.networkFee) / 100) + ((Plandata.poolPrize *
          Plandata.registerFee) / 100))) : 0



    const planincome = (Plandata?.referralReward * Plandata?.poolPrize / 100) + (Plandata?.ambpBonus * Plandata?.poolPrize / 100)

    console.log(usersingleData.data.data?.joinAmount / 1e18);

    setplanIncome((planincome * 2) + (usersingleData.data.data?.joinAmount - (((Plandata.poolPrize *
      Plandata.networkFee) / 100) + ((Plandata.poolPrize *
        Plandata.registerFee) / 100))))

    console.log(planincome, "balance");



    setacbalance(acbalan);
    setearnamount(earnedamount);

    setTimeout(() => {
      if (Planuserdata && Planuserdata.pendingCount >= 2 && Planuserdata && Planuserdata.userStaus == 0) {
        setexitpopup(true)
        window.$("#exit_popup").modal("show");
      } else {
        setexitpopup(false)
      }
    }, 20000);


  }

  const withdrawamount = async () => {
    try {
      if (
        Planuserdata &&
        Planuserdata.uplineAmount > 0 &&
        Planuserdata.refcount == 2
      ) {
      } else {
        toastAlert(
          "error",
          "Minimum 2 referrals required to Withdraw",
          "error"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  async function copyToClipboard(data) {
    navigator.clipboard.writeText(data);
    toastAlert("success", "Copied Successfully", "success");
  }

  console.log(Planuserdata, "Planuserdata");

  const rejoinplan = async (pid, amount) => {
    try {
      setbuttondiable(true)
      var get = await connection();
      var web3 = new Web3(get.web3);
      var approveamt = await convert(1000 * 1e18);
      var BuyAmount = await convert(amount * 1e18);
      var BusdBlanace = new web3.eth.Contract(BusdABI, config.BUSDAddress)
      const balace = await BusdBlanace.methods.balanceOf(Connection.address).call();
      let balac = balace / 1e18
      if (balac < amount) {
        toastAlert("error", "User usdt balance is Low", "error");
        setbuttondiable(false)
        return false
      }
      setload(true)

      await BusdBlanace.methods.approve(config.ambasscontract, approveamt.toString()).send({ gasLimit: 250000, from: Connection.address }).then(async (res) => {
        var mlmcontract = new web3.eth.Contract(DefiQABI, config.ambasscontract)
        await mlmcontract.methods.rejoin(pid, BuyAmount.toString()).send({ from: Connection.address }).then(async (res) => {

          const result = await getTransactionInfo(res.transactionHash);
          if (result) {
            setload(false);
            setbuttondiable(false)
            toastAlert("success", "Rejoin successfully", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }


          // toastAlert("success", "Rejoin successfully", "success");
          // setload(false)

          // setbuttondiable(false)
          // setTimeout(() => {
          //   window.location.reload()
          // }, 3000);
        })
      })

    } catch (err) {
      setload(false)
      setbuttondiable(false)

      toastAlert("error", "Transaction Rejected", "error");
      console.log(err);
    }
  }





  return (
    <div>
      {pageload && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      {load && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <ScrollToTopOnMount />
      <div className="dash_nav_wrap">
        <Navbar className="dash_nav_wrap" />
      </div>
      <div className="dashbg">
        <div className="inner_wrapper">
          <div className="container">
            {/* <ul
              className="nav nav-pills package_tab package_tab_fullwidth package_dashboard"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard-bronze">
                  <div className="bronze_tabbtn tabbtn">
                    <span>Bronze - 50 BUSD</span>
                    <small className="select_link active">Current Dashboard</small>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard-silver">
                  <div className="silver_tabbtn tabbtn">
                    <span>Silver - 100 BUSD</span>
                    <small className="select_link">Select</small>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard-gold">
                  <div className="gold_tabbtn tabbtn">
                    <span>Gold - 250 BUSD</span>
                    <small className="join_now_link">Join Now</small>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard-platinum">
                  <div className="platinum_tabbtn tabbtn">
                    <span>Platinum - 400 BUSD</span>
                    <small className="select_link">Select</small>
                  </div>
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard-diamond">
                  <div className="diamond_tabbtn tabbtn">
                    <span>Diamond - 550 BUSD</span>
                    <small className="select_link">Select</small>
                  </div>
                </NavLink>
              </li>
            </ul> */}
            <PlanInfo />


            <div className="dashboard_panel dashboard_panel_bronze">
              <div className="title_flex">
                <h2 className="inner_title">My Current Dashboard</h2>
              </div>


              <div className="dashboard_wrapper">
                <div className="dashboard_wrapper_left">
                  <div className="dashboard_wrapper_left_top align-items-unset">
                    <div className="box_width">
                      {/* <div class="dashboard_wrapper_inner_left mb-3">
                        <div class="std_box" style={{ width: "80%", margin: "auto" }}>
                          <div className="w-100" >
                            <h3 className="pb-0 after_none" >Parent Id</h3>
                            <h3 className="pt-1 after_here before_blw">500906</h3>
                            <h3 className="pb-0" style={{ fontSize: "14px" }} >My Id</h3>
                            <h3 className="pt-1" style={{ fontSize: "14px" }}>{Planuserdata && Planuserdata.userid}</h3>
                          </div>
                        </div>
                      </div> */}


                      <div className="dashboard_wrapper_inner_left mb-3">
                        <div className="std_box align-items-unset">
                          <div className="w-75" >
                            <h3 className="after_none" >Parent Id</h3>
                            <span className="bdr" ></span>
                            <span>
                              <h3 className="after_none" >My Id</h3>
                            </span>
                          </div>
                          <div className="no-border-right">
                            <h3 className="after_none" >{Planuserdata && Planuserdata.parentId}</h3>
                            <span className="bdr" ></span>
                            <span>
                              <h3 className="after_none" >{Planuserdata && Planuserdata.userid}</h3>
                            </span>
                          </div>
                        </div>
                      </div>


                      <div className="dashboard_wrapper_inner_left mb-3">

                        {/* {Planuserdata && Planuserdata.pendingCount >= 2 ?
                        <div className="purple_box_wrap text-center mb-4">
                          <div className="purple_box_wrap_title">
                            <h3> referral reward</h3>
                          </div>
                          <div className="w-100" >
                            <h5 className="mt-3 mb-2" >
                              Rejoin With {isNaN(Plandata && Plandata.poolPrize) ? 0 : Plandata && Plandata.poolPrize} <span>USDT</span> And Claim {isNaN(planIncome) ? 0 : planIncome} <span>USDT</span>
                            </h5>
                            <button class="secondary_btn  mt-1 mb-3"
                              disabled={getbuttondiable} onClick={() => rejoinplan(Plandata.PlanID, Plandata.poolPrize)}>Rejoin</button>

                          </div>
                        </div> : ""} */}

                        <div className="std_box align-items-unset">
                          <div className="d-flex justify-content-center" >
                            <h3 className="after_none my-auto" >{Plandata && Plandata.poolName.toUpperCase()}</h3>


                          </div>
                          <h2>
                            {Plandata && Plandata.poolPrize} <span>USDT</span>
                          </h2>
                        </div>

                      </div>

                      <div className="dashboard_wrapper_inner_left">
                        <div className="purple_box_wrap">
                          <div className="purple_box_wrap_title">
                            <h3>Share Referral ID</h3>
                          </div>
                          <div className="purple_box_wrap_body">
                            <div className="d-flex align-items-center justify-content-center">
                              <h4>
                                AMBPBronze{Planuserdata && Planuserdata.userid}
                              </h4>
                              <i
                                className="fas fa-copy"
                                onClick={() =>
                                  copyToClipboard(
                                    `${config.Front_URL}AMBPBRONZE${Planuserdata && Planuserdata.userid
                                    }`
                                  )
                                }
                              ></i>
                            </div>
                            <button
                              className="secondary_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#Share_model"
                            >
                              <span>
                                {" "}
                                <i className="fas fa-share-square me-2"></i> Send
                                Referral Link{" "}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="dashboard_wrapper_inner_right dashboard_wrapper_inner_right_chg">
                      <div className="green_box_wrap">
                        <div className="green_box_wrap_title">
                          <h2>Total Earned Income</h2>
                        </div>

                        <div className="green_box_wrap_body">
                          <h3>{isNaN(earnamount) ? 0 : earnamount} USDT</h3>
                        </div>
                        <div className="green_box_wrap_footer">
                          <div className="rewards_step">
                            <div>
                              <p>
                                Referral <br /> Rewards
                              </p>
                              <span
                                className={
                                  Planuserdata && Planuserdata.refcount == 0
                                    ? "earndata_opacity"
                                    : ""
                                }
                              >
                                1
                              </span>
                            </div>
                            <div>
                              <p>
                                AMBP <br /> Bonus
                              </p>
                              <span
                                className={
                                  Planuserdata && Planuserdata.refcount == 0
                                    ? "earndata_opacity"
                                    : ""
                                }
                              >
                                1
                              </span>
                            </div>
                            <div>
                              <p>
                                Referral <br /> Rewards
                              </p>
                              <span
                                className={
                                  Planuserdata && Planuserdata.refcount > 1
                                    ? ""
                                    : "earndata_opacity"
                                }
                              >
                                2
                              </span>
                            </div>
                            <div>
                              <p>
                                AMBP <br /> Bonus
                              </p>
                              <span
                                className={
                                  Planuserdata && Planuserdata.refcount > 1
                                    ? ""
                                    : "earndata_opacity"
                                }
                              >
                                2
                              </span>
                            </div>
                          </div>

                          {/* {Planuserdata && Planuserdata.refcount > 0 && */}
                          <ul className="rewards_step_list">
                            <li
                              className={
                                Planuserdata && Planuserdata.refcount > 0
                                  ? "active"
                                  : "fas fa-circle"
                              }
                            >
                              <i className="fas fa-circle"></i>
                            </li>
                            <li
                              className={
                                Planuserdata && Planuserdata.refcount > 0
                                  ? "active"
                                  : "fas fa-circle"
                              }
                            >
                              <i className="fas fa-circle"></i>
                            </li>
                            <li
                              className={
                                Planuserdata && Planuserdata.refcount > 1
                                  ? "active"
                                  : "fas fa-circle"
                              }
                            >
                              <i className="fas fa-circle"></i>
                            </li>
                            <li
                              className={
                                Planuserdata && Planuserdata.refcount > 1
                                  ? "active"
                                  : "fas fa-circle"
                              }
                            >
                              <i className="fas fa-circle"></i>
                            </li>
                          </ul>
                          {/* } */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard_wrapper_left_middle align-items-start">
                    <div className="dashboard_wrapper_inner_left">
                      <div className="purple_box_wrap">
                        <div className="purple_box_wrap_title">
                          <h3>Access to Handbook</h3>
                        </div>
                        <div className="purple_box_wrap_body">
                          <button
                            className="secondary_btn mt-1 mb-2"
                            onClick={() => handleDownload(`${config.Server_URL}uploads/${gethandbook?.file}`, gethandbook.originalname)}
                          >
                            Download
                          </button>


                        </div>
                      </div>
                    </div>

                    <div className="dashboard_wrapper_inner_right">
                      <div className="green_box_flex">
                        <div className="green_box_wrap">
                          <div className="green_box_wrap_title">
                            <h3>
                              Referral <br /> Rewards
                            </h3>
                          </div>
                          <div className="green_box_wrap_body">
                            <div>
                              <div className="reward_small_box_wrap">
                                <div
                                  className={
                                    Planuserdata && Planuserdata.refcount == 0
                                      ? "reward_small_box_opa"
                                      : "reward_small_box"
                                  }
                                >
                                  <h2>first</h2>
                                  <div>
                                    <h3>
                                      {(Plandata?.referralReward *
                                        Plandata?.poolPrize) /
                                        100}{" "}
                                      <span>USDT</span>
                                    </h3>
                                  </div>
                                </div>
                                <div
                                  className={
                                    Planuserdata && Planuserdata.refcount > 1
                                      ? "reward_small_box"
                                      : "reward_small_box_opa"
                                  }
                                >
                                  <h2>Second</h2>
                                  <div>
                                    <h3>
                                      {(Plandata?.referralReward *
                                        Plandata?.poolPrize) /
                                        100}{" "}
                                      <span>USDT</span>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              {Planuserdata && Planuserdata.refcount == 0 && (
                                <ul className="rewards_step_list rewards_step_list_small">
                                  <li>
                                    <i className="fas fa-circle"></i>
                                  </li>
                                  <li>
                                    <i className="fas fa-circle"></i>
                                  </li>
                                </ul>
                              )}
                              {Planuserdata && Planuserdata.refcount == 1 && (
                                <ul className="rewards_step_list rewards_step_list_small">
                                  <li className="active">
                                    <i className="fas fa-circle"></i>
                                  </li>
                                  <li>
                                    <i className="fas fa-circle"></i>
                                  </li>
                                </ul>
                              )}
                              {Planuserdata && Planuserdata.refcount == 2 && (
                                <ul className="rewards_step_list rewards_step_list_small">
                                  <li className="active">
                                    <i className="fas fa-circle"></i>
                                  </li>
                                  <li className="active">
                                    <i className="fas fa-circle"></i>
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="green_box_wrap">
                          <div className="green_box_wrap_title">
                            <h3>
                              AMBP <br /> Bonus
                            </h3>
                          </div>
                          <div className="green_box_wrap_body">
                            <div>
                              <div className="reward_small_box_wrap">
                                <div
                                  className={
                                    Planuserdata && Planuserdata.refcount == 0
                                      ? "reward_small_box_opa"
                                      : "reward_small_box"
                                  }
                                >
                                  <h2>first</h2>
                                  <div>
                                    <h3>
                                      {(Plandata?.ambpBonus *
                                        Plandata?.poolPrize) /
                                        100}
                                      <span>USDT</span>
                                    </h3>
                                  </div>
                                </div>
                                <div
                                  className={
                                    Planuserdata && Planuserdata.refcount > 1
                                      ? "reward_small_box"
                                      : "reward_small_box_opa"
                                  }
                                >
                                  <h2>Second</h2>
                                  <div>
                                    <h3>
                                      {(Plandata?.ambpBonus *
                                        Plandata?.poolPrize) /
                                        100}
                                      <span>USDT</span>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              {Planuserdata && Planuserdata.refcount == 0 && (
                                <ul className="rewards_step_list rewards_step_list_small">
                                  <li>
                                    <i className="fas fa-circle"></i>
                                  </li>
                                  <li>
                                    <i className="fas fa-circle"></i>
                                  </li>
                                </ul>
                              )}
                              {Planuserdata && Planuserdata.refcount == 1 && (
                                <ul className="rewards_step_list rewards_step_list_small">
                                  <li className="active">
                                    <i className="fas fa-circle"></i>
                                  </li>
                                  <li>
                                    <i className="fas fa-circle"></i>
                                  </li>
                                </ul>
                              )}
                              {Planuserdata && Planuserdata.refcount == 2 && (
                                <ul className="rewards_step_list rewards_step_list_small">
                                  <li className="active">
                                    <i className="fas fa-circle"></i>
                                  </li>
                                  <li className="active">
                                    <i className="fas fa-circle"></i>
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard_wrapper_left_bottom">

                    {/* <div className="dashboard_wrapper_inner_right">
                      <div className="green_box_wrap">
                        <div className="green_box_wrap_title">
                          <h2>My A/C Balance</h2>
                        </div>
                        <div className="green_box_wrap_body green_box_wrap_body_padding">
                          <div className="progress_wrap">
                            <h3> {acbalance} USDT</h3>
                            {Planuserdata && Planuserdata.refcount == 2 ? (
                              <p
                                className="secondary_btn secondary_btn_large mt-3 mb-3"
                                style={{ background: "#66ff66" }}
                              >
                                {" "}
                                Funds Claimed to Wallet
                              </p>
                            ) : (
                              <button
                                className="secondary_btn secondary_btn_large mt-3 mb-3"
                                onClick={withdrawamount}
                              >
                                {" "}
                                withdraw to wallet
                              </button>
                            )}

                            <div className="progress">
                              {Planuserdata && Planuserdata.refcount == 0 && (
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  aria-label="Animated striped example"
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: "0%" }}
                                ></div>
                              )}

                              {Planuserdata && Planuserdata.refcount == 1 && (
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  aria-label="Animated striped example"
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: "50%" }}
                                ></div>
                              )}

                              {Planuserdata && Planuserdata.refcount == 2 && (
                                <div
                                  className="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  aria-label="Animated striped example"
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: "100%" }}
                                ></div>
                              )}
                            </div>
                            <p className="progress_bar_note">
                              ({Planuserdata && Planuserdata.refcount} out of 2
                              referrals)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="dashboard_wrapper_right">
                  <div className="dashboard_wrapper_right_top">
                    <div className="purple_box_wrap">
                      <div className="purple_box_wrap_title">
                        <h2>Transactions</h2>
                      </div>
                      <div className="purple_box_wrap_body purple_box_wrap_body_pad30">
                        {Planuserdata && Planuserdata.refcount == 0 && (
                          <>
                            <ul className="todo_list">
                              <li className="completed_todo completed_green">
                                <i className="fas fa-circle"></i>
                                <div>
                                  <span>+{Plandata.poolPrize} USDT Invest</span>
                                </div>
                              </li>
                              <li className="completed_todo completed_red">
                                <i className="fas fa-circle"></i>
                                <div>
                                  <span>
                                    -
                                    {(Plandata.poolPrize *
                                      Plandata.registerFee) /
                                      100}{" "}
                                    USDT Registration Cost{" "}
                                    <small>(one time)</small>
                                  </span>
                                </div>
                              </li>
                              <li className="completed_todo completed_red">
                                <i className="fas fa-circle"></i>
                                <div>
                                  <span>
                                    -
                                    {(Plandata.poolPrize *
                                      Plandata.networkFee) /
                                      100}{" "}
                                    USDT Network Fee <small>(one time)</small>
                                  </span>
                                </div>
                              </li>
                              <li>
                                <i className="fas fa-circle"></i>
                                <div style={{ opacity: 0.3 }}>
                                  <span>
                                    +
                                    {(Plandata.referralReward *
                                      Plandata.poolPrize) /
                                      100}{" "}
                                    USDT First Referral Rewards
                                  </span>
                                </div>
                              </li>
                              <li>
                                <i className="fas fa-circle"></i>
                                <div style={{ opacity: 0.3 }}>
                                  <span>
                                    +
                                    {(Plandata.ambpBonus * Plandata.poolPrize) /
                                      100}{" "}
                                    USDT First AMBP Bonus
                                  </span>
                                </div>
                              </li>
                              <li>
                                <i className="fas fa-circle"></i>
                                <div style={{ opacity: 0.3 }}>
                                  <span>
                                    +
                                    {(Plandata.referralReward *
                                      Plandata.poolPrize) /
                                      100}{" "}
                                    USDT Second Referral Rewards
                                  </span>
                                </div>
                              </li>
                              <li>
                                <i className="fas fa-circle"></i>
                                <div style={{ opacity: 0.3 }}>
                                  <span>
                                    +
                                    {(Plandata.ambpBonus * Plandata.poolPrize) /
                                      100}{" "}
                                    USDT Second AMBP Bonus
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </>
                        )}

                        {Planuserdata && Planuserdata.refcount == 1 && (
                          <ul className="todo_list">
                            <li className="completed_todo completed_green">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>+{Plandata.poolPrize} USDT Invest</span>
                              </div>
                            </li>
                            <li className="completed_todo completed_red">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  -
                                  {(Plandata.poolPrize * Plandata.registerFee) /
                                    100}{" "}
                                  USDT Registration Cost{" "}
                                  <small>(one time)</small>
                                </span>
                              </div>
                            </li>
                            <li className="completed_todo completed_red">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  -
                                  {(Plandata.poolPrize * Plandata.networkFee) /
                                    100}{" "}
                                  USDT Network Fee <small>(one time)</small>
                                </span>
                              </div>
                            </li>

                            <li className="completed_todo completed_green">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  +
                                  {(Plandata.referralReward *
                                    Plandata.poolPrize) /
                                    100}{" "}
                                  USDT First Referral Rewards
                                </span>
                              </div>
                            </li>
                            <li className="completed_todo completed_green">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  +
                                  {(Plandata.ambpBonus * Plandata.poolPrize) /
                                    100}{" "}
                                  USDT First AMBP Bonus
                                </span>
                              </div>
                            </li>
                            <li>
                              <i className="fas fa-circle"></i>
                              <div style={{ opacity: 0.3 }}>
                                <span>
                                  +
                                  {(Plandata.referralReward *
                                    Plandata.poolPrize) /
                                    100}{" "}
                                  USDT Second Referral Rewards
                                </span>
                              </div>
                            </li>
                            <li>
                              <i className="fas fa-circle"></i>
                              <div style={{ opacity: 0.3 }}>
                                <span>
                                  +
                                  {(Plandata.ambpBonus * Plandata.poolPrize) /
                                    100}{" "}
                                  USDT Second AMBP Bonus{" "}
                                </span>
                              </div>
                            </li>
                          </ul>
                        )}

                        {Planuserdata && Planuserdata.refcount == 2 && (
                          <ul className="todo_list">
                            <li className="completed_todo completed_green">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>+{Plandata.poolPrize} USDT Invest</span>
                              </div>
                            </li>
                            <li className="completed_todo completed_red">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  -
                                  {(Plandata.poolPrize * Plandata.registerFee) /
                                    100}{" "}
                                  USDT Registration Cost{" "}
                                  <small>(one time)</small>
                                </span>
                              </div>
                            </li>
                            <li className="completed_todo completed_red">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  -
                                  {(Plandata.poolPrize * Plandata.networkFee) /
                                    100}{" "}
                                  USDT Network Fee <small>(one time)</small>
                                </span>
                              </div>
                            </li>

                            <li className="completed_todo completed_green">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  +
                                  {(Plandata.referralReward *
                                    Plandata.poolPrize) /
                                    100}{" "}
                                  USDT First Referral Rewards
                                </span>
                              </div>
                            </li>
                            <li className="completed_todo completed_green">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  +
                                  {(Plandata.ambpBonus * Plandata.poolPrize) /
                                    100}{" "}
                                  USDT First AMBP Bonus
                                </span>
                              </div>
                            </li>
                            <li className="completed_todo completed_green">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  +
                                  {(Plandata.referralReward *
                                    Plandata.poolPrize) /
                                    100}{" "}
                                  USDT Second Referral Rewards
                                </span>
                              </div>
                            </li>
                            <li className="completed_todo completed_green">
                              <i className="fas fa-circle"></i>
                              <div>
                                <span>
                                  +
                                  {(Plandata.ambpBonus * Plandata.poolPrize) /
                                    100}{" "}
                                  USDT Second AMBP Bonus
                                </span>
                              </div>
                            </li>
                          </ul>
                        )}

                        <div class="reward_small_box_opa rewards_total_box dark_purple">
                          <h2>Total To Collect</h2>
                          <div>
                            <h3>
                              {Planuserdata && Planuserdata.joinAmount == 0 ? 0 : isNaN(planIncome) ? 0 : planIncome} <span>USDT</span>
                            </h3>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Share
        referrallink={`${config.Front_URL}AMBPBRONZE${Planuserdata && Planuserdata.userid
          }`}
      />
      <div
        className="modal fade primary_modal"
        id="exit_popup"
        tabIndex={-1}
        aria-labelledby="exit_popupLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content extraforcenetr">
            <div className="modal-header checkkkk">
              <h5 className="modal-title" id="exit_popupLabel">
                Alert
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <div style={{ "display": "flex", "justify-content": "space-evenly" }}>
                  {Planuserdata && Planuserdata.pendingCount >= 2 && Planuserdata && Planuserdata.userStaus == 0 &&
                    <div className="w-100" >
                      <div>
                        <strong style={{ color: "white", fontSize: "20px" }}>YOU HAVE PENDING BONUS TO BE CLAIMED !
                        </strong>
                        <h5 style={{ color: "white", fontSize: "20px", marginTop: "7px" }}>${(Planuserdata && Planuserdata.pendingCount * planIncome) / 2}
                        </h5>
                      </div>
                      <div className="dashboard_panel_bronze">
                        <button class="secondary_btn px-4 mt-1"
                          disabled={getbuttondiable} onClick={() => rejoinplan(Plandata.PlanID, Plandata.poolPrize)}
                        >Rejoin & Claim <br></br>
                        </button>
                      </div>
                    </div>}
                </div>
              </div>
              <p style={{ color: "white", fontSize: "12px" }}>*NOTE:
                ONLY  {isNaN(planIncome) ? 0 : planIncome} USDT CLAIMABLE PER REJOIN.
                <br></br>
                YOU MAY REJOIN MULTIPLE TIMES TO AVAIL ENTIRE BONUS.
              </p>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
