import React from "react";

import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";


export default function Share(props) {


    return (
        <div
            className="modal fade primary_modal"
            id="Share_model"
            tabIndex={-1}
            aria-labelledby="joinnow_modalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="joinnow_ModalLabel">
                            Share
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group mb-3">
                            <div style={{ "display": "flex", "justify-content": "space-evenly" }}>

                                <WhatsappShareButton url={props.referrallink} >
                                    <WhatsappIcon size={50} round={true} />
                                </WhatsappShareButton>
                                <TelegramShareButton url={props.referrallink} >
                                    <TelegramIcon size={50} round={true} />
                                </TelegramShareButton>
                                <FacebookShareButton
                                    url={props.referrallink}
                                >
                                    <FacebookIcon size={50} round={true} />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={props.referrallink}
                                >
                                    <TwitterIcon size={50} round={true} />
                                </TwitterShareButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}