import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import Home from './pages/home.js';
import DashboardBronze from './pages/dashboard-bronze.js';

import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { bsc, bscTestnet, goerli, sepolia } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { Provider } from "react-redux";
import store from './store';
import config from "./config/config";

import DashboardSilver from './pages/dashboard-silver.js';
import DashboardGold from './pages/dashboard-gold.js';
import DashboardPlatinum from './pages/dashboard-platinum.js';
import DashboardDiamond from './pages/dashboard-diamond.js';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [infuraProvider({
    apiKey: config.projectId,
  }), publicProvider()],
)

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains, shimDisconnect: true }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: config.projectId,
        version: "2"
      },
    })
  ],
  publicClient,
  webSocketPublicClient,
});


const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/:ref", element: <Home /> },
    // { path: "/dashboard-bronze", element: <DashboardBronze /> },
    { path: "/dashboard-bronze/:id", element: <DashboardBronze /> },
    { path: "/dashboard-silver/:id", element: <DashboardSilver /> },
    { path: "/dashboard-gold/:id", element: <DashboardGold /> },
    { path: "/dashboard-platinum/:id", element: <DashboardPlatinum /> },
    { path: "/dashboard-diamond/:id", element: <DashboardDiamond /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Router basename="/">
        <ToastContainer />

        <WagmiConfig config={wconfigInitial}>

          <App />
        </WagmiConfig>
      </Router>
    </Provider>

  );
};

export default AppWrapper;