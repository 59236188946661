import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import TopInfo from "../components/TopInfo.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { WalletClient, useWalletClient } from "wagmi";
import config from "../config/config";
import { useSelector } from "react-redux";
import { connection } from "../helper/connection";
import {
  plandata,
  getuserplandata,
  getadmindetails,
  getearnpercentage,
} from "../Action/apiAction";
import DefiQABI from "../ABI/DefiQABI.json";
import { toastAlert } from "../helper/toastAlert";
import Web3 from "web3";
import { convert } from "../helper/convert";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";

import BusdABI from "../ABI/BusdABI.json";
import {
  approveAction,
  getAllowanceDetails,
  JoinNowAction,getTransactionInfo
} from "../Action/ContractActions.js";
import isEmpty from "is-empty";
import Dropdown from 'react-bootstrap/Dropdown';
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  var std_settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          infinite: true,
        },
      },
    ],
  };

  // let chainid = config.NetworkId;
  // const { data: walletClient } = useWalletClient({ chainid })

  // const [walletconnection, setwalletconnection] = useState({})

  const Connection = useSelector((state) => state.walletConnection);

  // useEffect(() => {
  //   getdetails()
  // }, [walletClient, Connection])

  // console.log(Connection, "Connection123")

  // async function getdetails() {
  //   // let datas = await Connection();
  //   // console.log(datas,"datasdatas");
  //   // setwalletconnection(datas)
  //   if (Connection && Connection.address &&
  //     Connection.address != undefined &&
  //     Connection.address != null &&
  //     Connection.address != "") {

  //     console.log(
  //       Connection.userdetails, "122");

  //     if (Connection.userdetails &&
  //       Connection.userdetails.isExist == false) {
  //       window.location.href = "/dashboard-bronze"
  //     } else {
  //     }
  //   }
  // }
  var { ref } = useParams();

  const [Plandata, setPlandata] = useState([]);
  const [userplandata, setuserplandata] = useState([]);
  const [allplandata, setallplandata] = useState([]);
  const [userPlanData, setuserPlanData] = useState([]);

  const [joinplanPid, setjoinplanPid] = useState(0);
  const [joinplanAmount, setjoinplanAmount] = useState();
  const [referalAddress, setReferalAddress] = useState(
    ref != undefined ? ref : ""
  );
  const [load, setload] = useState(false);
  const [Error, setError] = useState("");

  const [adminreferrealdata, setadminreferrealdata] = useState([]);

  const [addfrom, setaddfrom] = useState(false);
  const [planName, setplanName] = useState();

  const [percentage, setpercentage] = useState(0);

  const [selectplandata, setselectplandata] = useState([])
  const [selectedValue, setSelectedValue] = useState([]);
  const [getbuttondiable, setbuttondiable] = useState(false);

  console.log(userPlanData, "selectedValue");

  useEffect(() => {
    getuser(Connection.address);
    getpercentage();


  }, [Connection]);

  const getpercentage = async () => {
    try {
      const result = await getearnpercentage();
      console.log(result, "12");
      console.log(result?.data?.data[0].percentage, "123");
      setpercentage(result?.data?.data[0].percentage);
    } catch (err) {
      console.log(err);
    }
  };

  const getuser = async (add) => {
    const result = await plandata();
    let reqdata = {
      address: add,
    };
    console.log(reqdata);
    const userdata = await getuserplandata(reqdata);
    console.log(userdata.data.data, result.data.data, "result");
    setuserPlanData(userdata.data.data);
    setallplandata(result.data.data);
    setselectplandata(result?.data?.data[0])

    setSelectedValue(result?.data?.data[0])
    const userplan = await samebetweenarray(
      userdata.data.data,
      result.data.data
    );
    const balplan = await differencebetweenarrayvalue(
      userdata.data.data,
      result.data.data
    );
    setPlandata(balplan);
    setuserplandata(userplan);
    checkParamsID(userdata.data.data)
  };

  const samebetweenarray = async (arr1, arr2) => {
    return arr2.filter((item1) => {
      return arr1.some((item2) => {
        if (item2.PlanID === item1.PlanID) {
          return item2;
        }
      });
    });
  };

  const handlechange = (e) => {
    const { value } = e.target;
    setError();
    setReferalAddress(value);
  };

  const handleclose = (e) => {
    // alert(e.target.value)
    setReferalAddress("");
    setError("");
    setaddfrom(false);
  };

  const differencebetweenarrayvalue = async (arr1, arr2) => {
    return arr2.filter((item1) => {
      return !arr1.some((item2) => item2.PlanID === item1.PlanID);
    });
  };

  const onclickrejoindata = (amt, pid, name) => {
    setjoinplanAmount(amt);
    setjoinplanPid(pid);
    setplanName(name);

    console.log(amt, pid, "amt, pid");
    getadmindata(pid);
  };

  const getadmindata = async (pid) => {
    try {
      let reqdata = {
        address: config.AdminAddress,
        planid: pid,
      };

      console.log(reqdata, "reqdata");


      const result = await getadmindetails(reqdata);
      console.log(result, "result");
      setadminreferrealdata(result.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const joinPlan = async (type) => {
    try {
      if (type == "with") {
        if (referalAddress == "") {
          setError("Continue without referral Id");
          return false;
        }
      }

      let refferal =
        referalAddress == "" ? `AMBPSILVER${adminreferrealdata[0].userid}` : referalAddress;

      setload(true);
      console.log(
        joinplanPid,
        joinplanAmount,
        Connection.address,
        referalAddress,
        refferal,
        "SaranRaj"
      );


      const { status, message } = await JoinNowAction(
        joinplanPid,
        joinplanAmount,
        Connection.address,
        refferal
      );

      // if (status) {
      //   toastAlert("success", message, "success");
      //   setTimeout(() => {
      //     window.location.reload()
      //   }, 1500);
      // } else {

      toastAlert("error", message, "error");
      setload(false);
      // }

      if (message == "Invalid Referral id") {
        setReferalAddress("");
        setError("Invalid Referral id");
        return false;
      }
      console.log(status, message);
    } catch (err) {
      console.log(err, "errerrerrerrsaran");
      setload(false);
    }
  };

  const rejoinplan = async (pid, amount) => {
    try {
      setbuttondiable(true)

      var get = await connection();
      var web3 = new Web3(get.web3);
      var approveamt = await convert(1000 * 1e18);
      var BuyAmount = await convert(amount * 1e18);

      var BusdBlanace = new web3.eth.Contract(BusdABI, config.BUSDAddress);

      const balace = await BusdBlanace.methods
        .balanceOf(Connection.address)
        .call();

      let balac = balace / 1e18;
      if (balac < amount) {
        setload(false);
        setbuttondiable(false)
        toastAlert("error", "User Usdt balance is Low", "error");
        return false;
      }

      setload(true);
      await BusdBlanace.methods
        .approve(config.ambasscontract, approveamt.toString())
        .send({ gasLimit: 250000, from: Connection.address })
        .then(async (res) => {
          var mlmcontract = new web3.eth.Contract(
            DefiQABI,
            config.ambasscontract
          );
          await mlmcontract.methods
            .rejoin(pid, BuyAmount.toString())
            .send({ from: Connection.address })
            .then(async(res) => {

              const result = await getTransactionInfo(res.transactionHash);
              console.log(result, "getTransactionInfo");
              
              if (result) {
                setload(false);
                setbuttondiable(false)
                toastAlert("success", "Rejoin successfully", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            });
        });
    } catch (err) {
      setload(false);
      toastAlert("error", "Transaction Rejected", "error");
      setbuttondiable(false)
      console.log(err);
    }
  };

  // const rejoinplan = async (pid, amount) => {
  //     try {
  //         var get = await connection();
  //         var web3 = new Web3(get.web3);
  //         var approveamt = await convert(1000 * 1e18);
  //         var BuyAmount = await convert(amount * 1e18);

  //         var BusdBlanace = new web3.eth.Contract(BusdABI, config.BUSDAddress)

  //         await BusdBlanace.methods.approve(config.ambasscontract, approveamt.toString()).send({ gasLimit: 250000, from: Connection.address }).then(async (res) => {
  //             var mlmcontract = new web3.eth.Contract(DefiQABI, config.ambasscontract)
  //             await mlmcontract.methods.rejoin(pid, BuyAmount.toString()).send({ from: Connection.address }).then(async (res) => {
  //                 console.log(res);

  //             })
  //         })

  //     } catch (err) {
  //         console.log(err);
  //     }
  // }



  const handleSelect = (eventKey) => {

    const data = allplandata.find(
      ({ PlanID }) => PlanID == eventKey
    )
    console.log(data);
    setselectplandata(data)
    setSelectedValue(data);
  };



  const checkParamsID = (UserData) => {
    try {

      let outputdata = ref.replace(/\D/g, "");
      let idArr = []
      UserData.map((item) => {
        return idArr.push(item.parentId)
      })
      const isthere = idArr.includes(outputdata)

      if (idArr.length != 0 && !isthere) {
        window.$("#warning_modal2").modal("show");
      }

      console.log(idArr, isthere, ref, "isthere");

    } catch (err) {
      console.log(err);
    }

  }

  const closePop = () => {
    window.$("#warning_modal2").modal("hide");
  }


  return (
    <div>
      {load && (
        <ReactLoading type={"spin"} color={"#000000"} className="loading" />
      )}
      <ScrollToTopOnMount />
      <TopInfo />
      <Navbar />
      <div className="page_header padd">
        <div className="container">
          <div className="page_header">
            <div className="row align-items-center">
              <div className="col-xl-6">
                <h1 data-aos="fade-up">
                  Earn <span>{percentage}% PROFIT</span> by utilizing the power
                  of network equity.
                </h1>
                <div className="row">
                  <div className="col-lg-8 col-xl-12">
                    <div className="bannerSlider mb-5" data-aos="fade-up" >
                      <Slider {...std_settings} className="std_slider">
                        {allplandata.map((item, index) => (
                          <div key={index}>
                            <div className="std_slider_box">
                              <div>
                                <div
                                  class={`standard_circle standard_${item.PoolColor.toLowerCase()}`}
                                ></div>
                                <h3>{item.poolName.toUpperCase()}</h3>
                              </div>
                              <h2>{item.poolPrize} USDT</h2>
                              Invest <span>{item.poolPrize} USDT</span>
                              <br />Collect <span>{(((item.referralReward * item.poolPrize / 100) + (item.ambpBonus * item.poolPrize / 100)) * 2) + item.poolPrize - ((item.poolPrize * item.networkFee) / 100 + ((item.poolPrize * item.registerFee) / 100))} USDT</span>

                              <br />
                              <br />
                              {Connection && Connection.address != "" ? (
                                userplandata.findIndex(
                                  (planUser) => planUser.PlanID === item.PlanID
                                ) != -1 ? (
                                  // userPlanData.findIndex(planUser => planUser.uplineAmount === 0) != -1 &&
                                  userPlanData.findIndex(
                                    (planUser) =>
                                      planUser.userStaus === 0 &&
                                      planUser.PlanID === item.PlanID
                                  ) != -1 ? (
                                    <button
                                      className="select_link active"
                                      disabled={getbuttondiable}
                                      onClick={() =>
                                        rejoinplan(item.PlanID, item.poolPrize)
                                      }
                                      style={{ width: "120px" }}
                                    >
                                      ReJoin
                                    </button>
                                  ) : (
                                    <a
                                      href={`/dashboard-${item.PoolColor.toLowerCase()}/${item.PlanID
                                        }`}
                                    >
                                      <small className="select_link">
                                        Current Dashboard
                                      </small>
                                    </a>
                                  )
                                ) : (
                                  <button
                                    className="select_link"
                                    data-bs-toggle="modal"
                                    data-bs-target="#joinnow_modal2"
                                    // onClick={() => rejoinplan(item.PlanID, item.poolPrize)}
                                    onClick={() =>
                                      onclickrejoindata(
                                        item.poolPrize,
                                        item.PlanID,
                                        item.poolName
                                      )
                                    }
                                  >
                                    Join Now
                                  </button>
                                )
                              ) : (
                                <button
                                  class="std_join_btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#connect_wallet_modal1"
                                >
                                  Connect Wallet
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        {/* <div>
                    <div className="std_slider_box">
                      <div>
                        <div class="standard_circle standard_silver"></div>
                        <h3>Silver</h3>
                      </div>
                      <h2>100 BUSD</h2>
                      <button
                        class="std_join_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#joinnow_modal"
                      >
                        Join
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="std_slider_box">
                      <div>
                        <div class="standard_circle standard_gold"></div>
                        <h3>Gold</h3>
                      </div>
                      <h2>250 BUSD</h2>
                      <button
                        class="std_join_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#joinnow_modal"
                      >
                        Join
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="std_slider_box">
                      <div>
                        <div class="standard_circle standard_platinum"></div>
                        <h3>Platinum</h3>
                      </div>
                      <h2>400 BUSD</h2>
                      <button
                        class="std_join_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#joinnow_modal"
                      >
                        Join
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="std_slider_box">
                      <div>
                        <div class="standard_circle standard_diamond"></div>
                        <h3>Diamond</h3>
                      </div>
                      <h2>550 BUSD</h2>
                      <button
                        class="std_join_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#joinnow_modal"
                      >
                        Join
                      </button>
                    </div>
                  </div> */}
                      </Slider>
                    </div>
                  </div>
                </div>

                {/* <h5 data-aos="fade-up" data-aos-duration="500">
                  NO HIDDEN COSTS
                </h5> */}
                <ul
                  className="custom_list"
                  data-aos="fade-up"
                  // data-aos-duration="500"
                >
                  <li>
                    Registration Cost (One-time per program)
                  </li>
                  <li>
                    Network fee (One-time per program)
                  </li>
                </ul>
                <h5
                  className="mt-2"
                  data-aos="fade-up"
                  // data-aos-duration="500"
                >
                  USDT is pegged 1:1 with US Dollar
                </h5>
                {/* {Connection && Connection.address != "" ? (
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#choose_standard_modal" data-aos="fade-up" data-aos-duration="500"
                  >
                    JOIN Now
                  </button>
                ) : (
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal1" data-aos="fade-up" data-aos-duration="500"
                  >
                    JOIN Now
                  </button>
                )} */}
              </div>
              {/* {walletConnection && walletConnection.address != '' && */}

              {/* } */}
              <div className="col-xl-6 d-none d-xl-block">
                <img
                  src={require("../assets/images/banner_bg.png")}
                  alt="Banner"
                  className="img-fluid"
                  // data-aos="fade-up"
                  // data-aos-duration="500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="section dark_bg">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6"
                // data-aos="fade-up"
                // data-aos-duration="500"
              >
                <div className="referral_div">
                  <img
                    src={require("../assets/images/img_usdt.png")}
                    alt="REFERRAL REWARDS"
                    className="img-fluid"
                  />
                  <div>
                    <h3>REFERRAL REWARDS</h3>
                    <h4>
                      <span>10%</span> of investment amount / per referral
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6"
                // data-aos="fade-up"
                // data-aos-duration="500"
              >
                <div className="referral_div">
                  <img
                    src={require("../assets/images/img_02_new.png")}
                    alt="REFERRAL REWARDS"
                    className="img-fluid"
                  />
                  <div>
                    <h3>AMBP BONUS</h3>
                    <h4>
                      <span>25%</span> of investment amount / per referral
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-10 col-lg-9 m-auto">
                <p
                  className="text-center main_para"
                  // data-aos="fade-up"
                  // data-aos-duration="500"
                >
                  On a maximum of two referrals joining with your referral ID,
                  the smart contract technology automatically terminates the
                  user from the Ambassador Program and sends back the total
                  principal and an extra profit of 40% on top to the user.
                </p>
                <p
                  className="text-center main_para"
                  // data-aos="fade-up"
                  // data-aos-duration="500"
                >
                  Please request referral ID from those who have referred you
                </p>
                <div
                  className="referral_note"
                  // data-aos="fade-up"
                  // data-aos-duration="500"
                >
                  <p className="main_para mb-0">
                    <b>NOTE:</b> For your referral ID to be active, you must be
                    active in the same standard of the Ambassador Program at the
                    time of the referral joining, in order for you to get the
                    benefits from the new admission into the program.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                // data-aos="fade-up"
                // data-aos-duration="500"
              >
                <h2 className="main_title text-center">Standards</h2>
                <div className="banner_info" 
                // data-aos="fade-up"
                //  data-aos-duration="500"
                 >
                  {allplandata.map((item, index) => (
                    <div className="std_slider_box">
                      <div>
                        <div class={`standard_circle standard_${item.PoolColor.toLowerCase()}`}></div>
                        <h3>{item.poolName}</h3>
                      </div>
                      Invest <span>{item.poolPrize} USDT</span>


                      <br /> collect <span>{(((item.referralReward * item.poolPrize / 100) + (item.ambpBonus * item.poolPrize / 100)) * 2) + item.poolPrize - ((item.poolPrize * item.networkFee) / 100 + ((item.poolPrize * item.registerFee) / 100))} USDT</span>
                    </div>))}
                  {/* <div className="std_slider_box">
                    <div>
                      <div class="standard_circle standard_bronze"></div>
                      <h3>BRONZE</h3>
                    </div>
                    Invest <span>10 BUSD</span>
                    <br /> collect <span>14 BUSD</span>
                  </div>
                  <div className="std_slider_box">
                    <div>
                      <div class="standard_circle standard_silver"></div>
                      <h3>SILVER</h3>
                    </div>
                    Invest <span>50 BUSD</span> <br />
                    collect <span>70 BUSD</span>
                  </div>
                  <div className="std_slider_box">
                    <div>
                      <div class="standard_circle standard_gold"></div>
                      <h3>GOLD</h3>
                    </div>
                    Invest <span>100 BUSD</span>
                    <br /> collect <span>140 BUSD</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section dark_bg earning_section">
          <div className="container">
            <div className="row mt-5">
              <div
                className="col-md-10 col-lg-9 m-auto"
                // data-aos="fade-up"
                // data-aos-duration="500"
              >
                <h2 className="main_title text-center">Earnings</h2>
                <p className="text-center main_para">
                  40% Profit on principal investment per program automatically
                  distributed via blockchain smart contract technology
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div
                className="col-lg-5"
                // data-aos="fade-up"
                // data-aos-duration="500"
              >
                <div className="text-center">
                  <img
                    src={require("../assets/images/imgusdt.png")}
                    alt="Earnings"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div
                className="col-lg-7"
                // data-aos="fade-up"
                // data-aos-duration="500"
              >
                <h3>EARNINGS PER STANDARDS</h3>
                {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="one-tab" data-bs-toggle="tab" data-bs-target="#one-tab-pane" type="button" role="tab" aria-controls="one-tab-pane" aria-selected="true">
      Bronze
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="two-tab" data-bs-toggle="tab" data-bs-target="#two-tab-pane" type="button" role="tab" aria-controls="two-tab-pane" aria-selected="false">
      Silver
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="three-tab" data-bs-toggle="tab" data-bs-target="#three-tab-pane" type="button" role="tab" aria-controls="three-tab-pane" aria-selected="false">
      Gold
    </button>
  </li> 
   </ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="one-tab-pane" role="tabpanel" aria-labelledby="one-tab" tabindex="0">
  <div>
                <p className="main_para">Example</p>
                <p className="main_para">
                  When you invest 50 BUSD in Bronze Standard of ambassador
                  program
                </p>
                <ul className="custom_list">
                  <li>
                    Referral Reward = 10% *50 BUSD = <b>5 BUSD</b>
                  </li>
                  <li>
                    AMBASSADOR PROGRAM (AMBP) Bonus = 25% *50 BUSD ={" "}
                    <b>$12.5 BUSD</b>
                  </li>
                </ul>
                <h4>
                  TOTAL = <span>17.5 BUSD PER REFERRAL</span>
                </h4>
    </div>
  </div>
  <div class="tab-pane fade" id="two-tab-pane" role="tabpanel" aria-labelledby="two-tab" tabindex="0">
    <div>
                <p className="main_para">Example</p>
                <p className="main_para">
                  When you invest 100 BUSD in Silver Standard of ambassador
                  program
                </p>
                <ul className="custom_list">
                  <li>
                    Referral Reward = 10% *100 BUSD = <b>10 BUSD</b>
                  </li>
                  <li>
                    AMBASSADOR PROGRAM (AMBP) Bonus = 25% *100 BUSD ={" "}
                    <b>$12.5 BUSD</b>
                  </li>
                </ul>
                <h4>
                  TOTAL = <span>17.5 BUSD PER REFERRAL</span>
                </h4>
    </div>
  </div>
  <div class="tab-pane fade" id="three-tab-pane" role="tabpanel" aria-labelledby="three-tab" tabindex="0">
   <div>
                <p className="main_para">Example</p>
                <p className="main_para">
                  When you invest 200 BUSD in Gold Standard of ambassador
                  program
                </p>
                <ul className="custom_list">
                  <li>
                    Referral Reward = 10% *200 BUSD = <b>20 BUSD</b>
                  </li>
                  <li>
                    AMBASSADOR PROGRAM (AMBP) Bonus = 25% *200 BUSD ={" "}
                    <b>$12.5 BUSD</b>
                  </li>
                </ul>
                <h4>
                  TOTAL = <span>17.5 BUSD PER REFERRAL</span>
                </h4>
     </div>
   </div> 
</div> */}
                <div className="d-flex align-items-center" >
                  <label className="mb-3 me-2" >Choose value : </label>
                  <Dropdown onSelect={handleSelect} className="mb-3">
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ display: "flex" }}>
                      <div class={`standard_circle standard_${selectedValue?.PoolColor?.toLowerCase()}`}></div>
                      {selectedValue?.poolName}&nbsp; {selectedValue?.poolPrize} USDT

                    </Dropdown.Toggle>


                    <Dropdown.Menu  >
                      {allplandata?.map((item, index) => (

                        <Dropdown.Item eventKey={item.PlanID} style={{ display: "flex" }} className={item?.PlanID == selectedValue?.PlanID ? "select_color" : ""} >
                          <div class={`standard_circle standard_${item?.PoolColor?.toLowerCase()}`}

                          ></div>
                          {item?.poolName}&nbsp;{item?.poolPrize} USDT</Dropdown.Item>

                      ))}

                    </Dropdown.Menu>

                  </Dropdown>
                </div>

                <div>
                  <p className="main_para">Example</p>
                  <p className="main_para">
                    When you invest {selectplandata?.poolPrize} USDT in {selectplandata?.poolName} Standard of Ambassador
                    Program
                  </p>
                  <ul className="custom_list">
                    <li>
                      Registration  Cost = - {(selectplandata?.poolPrize *
                        selectplandata?.registerFee) /
                        100} USDT
                    </li>
                    <li>
                      Network fee = - {(selectplandata?.poolPrize *
                        selectplandata?.networkFee) /
                        100} USDT
                    </li>
                  </ul>
                  <ul className="custom_list">
                    <li>
                      Referral Reward 1 =  {selectplandata?.referralReward}% * {selectplandata?.poolPrize} USDT = <b>+ {(selectplandata?.referralReward * selectplandata?.poolPrize / 100)} USDT</b>
                    </li>
                    <li>
                      Ambassador
                      Program(AMBP) Bonus 1 = {selectplandata?.ambpBonus}% *{selectplandata?.poolPrize} USDT ={" "}
                      <b>+ {(selectplandata?.ambpBonus * selectplandata?.poolPrize / 100)} USDT</b>
                    </li>
                  </ul>

                  <ul className="custom_list">
                    <li>
                      Referral Reward 2 =  {selectplandata?.referralReward}% * {selectplandata?.poolPrize} USDT = <b>+ {(selectplandata?.referralReward * selectplandata?.poolPrize / 100)} USDT</b>
                    </li>
                    <li>
                      Ambassador
                      Program(AMBP) Bonus 2 = {selectplandata?.ambpBonus}% *{selectplandata?.poolPrize} USDT ={" "}
                      <b>+ {(selectplandata?.ambpBonus * selectplandata?.poolPrize / 100)} USDT</b>
                    </li>
                  </ul>

                  <h4>
                    TOTAL = <span>{(((selectplandata?.referralReward * selectplandata?.poolPrize / 100) + (selectplandata?.ambpBonus * selectplandata?.poolPrize / 100)) * 2) + selectplandata?.poolPrize - ((selectplandata?.poolPrize * selectplandata?.networkFee) / 100 + ((selectplandata?.poolPrize * selectplandata?.registerFee) / 100))}  USDT</span>
                  </h4>
                </div>

                <p className="main_para main_para_italic">
                  (Automatically disbursed upon Second referral joining with your
                  referral ID)
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-10 col-lg-9 m-auto">
                <h2 className="main_title text-center">FAQ</h2>
                <div
                  className="faq_accordion"
                  // data-aos="fade-up"
                  // data-aos-duration="500"
                >
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item active">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <span>Q1. WHAT IS AMBP BONUS?</span>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            The Ambassador Program Bonus is given from the
                            program for successfully recruiting a new user
                            through your referral ID.
                          </p>
                          <p className="inner_para">
                            It is an automatic payout based on the fund
                            disbursing blockchain mechanism.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <span>Q2. HOW MANY REFERRALS CAN I REFER?</span>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            After 2 referrals, the Ambassador Program will
                            automatically terminate your program.
                          </p>
                          <p className="inner_para">
                            You must ensure that you re-invest by rejoining the
                            program to continue you to receive earnings from
                            more than 2 referrals with the same referral ID.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <span>
                            Q3. CAN I GET THE BENEFIT OF REFERRALS USING MY
                            REFERRAL ID BUT JOINING STANDARDS THAT ARE DIFFERENT
                            FROM MINE?
                          </span>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            No. You must share the referral ID of the same
                            program and your referral must join the same
                            standard in order for you to receive the extra bonus
                            and reward.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <span>
                            Q4. CAN I JOIN IN MULTIPLE STANDARDS AT THE SAME
                            TIME?
                          </span>
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            Yes. On the same wallet, you can join different
                            standards simultaneously.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          <span>
                            Q5. IS MY INVESTMENT SAFE WHEN JOINING THIS PROGRAM?
                          </span>
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            100% safe because this system is built with smart
                            contracts that automates the actions and
                            requirements based on conditions that would
                            otherwise be completed by the parties in the
                            agreement. Using decentralized mechanisms removes
                            the need for both parties to trust each other.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>

      <div
        className="modal fade primary_modal"
        id="joinnow_modal2"
        tabIndex={-1}
        aria-labelledby="joinnow_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="joinnow_ModalLabel">
                Join Now
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleclose}
              ></button>
            </div>
            <div className="modal-body">
              {addfrom && (
                <div className="form-group mb-3">
                  <input
                    placeholder="Enter Referral ID"
                    className="form-control primary_inp"
                    onChange={handlechange}
                    value={ref != undefined ? ref : referalAddress}
                  />
                  <small
                    style={{
                      fontSize: "12px",
                      float: "right",
                      display: "block !important",
                      textAlign: "right !important",
                    }}
                  >
                    Example:AMBP{planName.toUpperCase()}12345
                  </small>

                  {!isEmpty(Error) ? (
                    <span style={{ color: "red" }} className="errorText">
                      {Error}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="text-center" style={{ display: "flex" }}>
                {!addfrom && (
                  <button
                    class="primary_btn"
                    style={{ marginRight: "20px" }}
                    onClick={() => setaddfrom(true)}
                  >
                    Add referral
                  </button>
                )}

                {addfrom && isEmpty(Error) && (
                  <button class="primary_btn" onClick={() => joinPlan("with")}>
                    submit
                  </button>
                )}

                <br></br>
                {!addfrom && (
                  <button
                    class="primary_btn"
                    onClick={() => joinPlan("without")}
                  >
                    Continue without referral
                  </button>
                )}
                {!isEmpty(Error) && (
                  <button
                    class="primary_btn"
                    style={{ marginRight: "20px" }}
                    onClick={() => setError("")}
                  >
                    Add referral
                  </button>
                )}
                {!isEmpty(Error) && (
                  <button
                    class="primary_btn"
                    style={{ marginRight: "20px" }}
                    onClick={() => joinPlan("without")}
                  >
                    Continue without referral
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div
        className="modal fade primary_modal"
        id="warning_modal2"
        tabindex="{-1}"
        aria-labelledby="warning_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="warning_modalLabel">ALERT</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <div style={{ "display": "flex", "justify-content": "space-evenly" }}>
                  <div className="w-100" >
                    <strong style={{ color: "white", fontSize: "20px" }}>THIS WALLET IS ALREADY CONNECTED WITH A DIFFERENT REFERRAL ID.
                    </strong>
                    {/* <br></br> */}
                    <div className="text-center" style={{ display: "flex" }}>
                      <br></br>

                      <button
                        class="primary_btn mt-3"
                      // style={{ marginRight: "20px" }}
                      onClick={closePop}
                      >
                        OKAY                  </button>
                    </div>


                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
