import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import $ from "jquery";

import { getmoreinfo } from "../Action/apiAction"
import { useSelector } from "react-redux";

import DefiQABI from '../ABI/DefiQABI.json'
import config from '../config/config'
import { connection } from "../helper/connection"
import Web3 from "web3";
import moment from "moment";

export default function TopInfo() {
  const [details, setdetails] = useState({});
  const [alluser, setalluser] = useState()
  const [onedayuser, setonedayuser] = useState()
  const [overallearnings, setoverallearnings] = useState()

  const Connection = useSelector((state) => state.walletConnection)


  useEffect(() => {
    loadScript1();
    getinfodetails()

  }, [Connection]);



  function loadScript1() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".above_info_box").addClass("above_fixed");
      } else {
        $(".above_info_box").removeClass("above_fixed");
      }
    });
  }



  const getinfodetails = async () => {
    try {

      // var get = await connection();

      var web3 = new Web3(config.rpcurl);

      var mlmcontract = new web3.eth.Contract(DefiQABI, config.ambasscontract)
      var refId = await mlmcontract.methods.overAllIncome().call();

      console.log(refId / 1e18);
      setoverallearnings(refId / 1e18)

      const result = await getmoreinfo()
      console.log(result);
      setalluser(result.data.data?.user)

      const today = moment(Date.now()).format("M,D,YYYY")
      if (result.data.data.onedayuser.length != 0) {
        for (var i = 0; i < result.data.data.onedayuser.length; i++) {
          var changevalue = moment(result.data.data.onedayuser[i]._id).format("M,D,YYYY")
          console.log(changevalue, "changevalue");
          if (changevalue == today) {
            // console.log(result.data.data.onedayuser[i]?.count);  
            setonedayuser(result.data.data.onedayuser[i]?.count ? result.data.data.onedayuser[i]?.count : 0)
          } else {
            setonedayuser(0)

          }
        }
      } else {
        setonedayuser(result.data.data.onedayuser[0]?.count ? result.data.data.onedayuser[0]?.count : 0)

      }


      console.log(today, "today");


    } catch (err) {
      console.log(err);

    }

  }



  return (
    <section className="above_info_box">
      <div className="container">
        <Scrollbars
          style={{ width: "100%", height: 21 }}
          renderThumbHorizontal={(props) => (
            <div {...props} className="thumb-horizontal" />
          )}
        >
          <div className="above_box_flx">
            <div>
              <p>All participants</p>
              <span>{alluser}</span>
            </div>
            <div>
              {/* <p>Joined in 24 hours </p> */}
              <p>Today Joined participants </p>

              <span>{onedayuser}</span>
            </div>
            <div>
              <p>Total investment by users</p>
              <span>{overallearnings}&nbsp;USDT</span>
            </div>
          </div>
        </Scrollbars>
      </div>
    </section>
  );
}
